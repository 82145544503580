@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    color: #fff;
    background: #0D0D0D;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #0D0D0D inset !important;
}

input:focus-visible {
    border: none;
    outline: none
}

.swiper-wrapper {
    transition-duration: 1550ms !important;
    padding-bottom: 35px
}

.swiper-pagination {
    position: absolute;
    bottom: 0 !important
}

.swiper-pagination-bullet {
    background-color: transparent !important;
    width: 18px !important;
    height: 18px !important;
    border: 2px solid #707070
}

.swiper-pagination-bullet-active {
    background-color: #707070 !important;
    width: 18px !important;
    height: 18px !important;
    border: 2px solid #707070
}